import {HttpClient, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {ToastrModule} from 'ngx-toastr';

import {NgSelectModule} from '@ng-select/ng-select';
import {DragulaModule} from 'ng2-dragula';
import {LoginComponent} from './auth/login/login.component';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import {HtpInterceptor} from './shared/services/interceptor.service';
import {GlobalService} from './shared/services/global.service';
import {AlertService} from './shared/services/alert.service';
import {NotificationComponent} from './components/notification/notification.component';
import {ProfileComponent} from './components/profile/profile.component';
import { NotifyComponent } from './components/notify/notify.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotificationComponent,
    ProfileComponent,
    NotifyComponent
  ],
  imports: [
    BrowserModule,
    DragulaModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    ToastrModule.forRoot(),
    NgSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    SnotifyModule,
  ],
  providers: [
    HtpInterceptor,
    GlobalService,
    AlertService,
    {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
