import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbModal, NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap';
import {StorageService} from '../../shared/services/storage.service';
import {StudentService} from '../../shared/services/student.service';

@Component({
  selector: 'ps-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  password_icon: boolean = false;
  addProfileForm: FormGroup;
  user:any;

  constructor(config: NgbPaginationConfig,
              private modalService: NgbModal,
              private fb: FormBuilder,
              private studentService: StudentService) {
    config.size = 'sm';
    config.boundaryLinks = true;
    this.user = StorageService.getItem('self');
  }

  ngOnInit(): void {
    this.addProfileForm = this.fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      username: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', Validators.required],
    });

    this.getStudentDetail();
  }

  update() {
  }

  openLg(content) {
    this.modalService.open(content, {size: 'lg'});
  }

  passwordIcon() {
    this.password_icon === true;
  }

  getStudentDetail() {
    this.studentService.detail(this.user.user_id).subscribe(data => {
      if (data) {
        this.addProfileForm.patchValue({
          'name': (this.addProfileForm && this.addProfileForm.getRawValue() && this.addProfileForm.getRawValue().name) ? this.addProfileForm.getRawValue().name : '',
          'email': (this.addProfileForm && this.addProfileForm.getRawValue() && this.addProfileForm.getRawValue().email) ? this.addProfileForm.getRawValue().email : '',
          'contact': (this.addProfileForm && this.addProfileForm.getRawValue() && this.addProfileForm.getRawValue().contact) ? this.addProfileForm.getRawValue().contact : '',
          'course_id': (this.addProfileForm && this.addProfileForm.getRawValue() && this.addProfileForm.getRawValue().course_id) ? this.addProfileForm.getRawValue().course_id : ''
        });
      }
    });
  }

}
