import {HostListener, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})

export class NavService {

  constructor() {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebarMainMenu = false;
      this.collapseSidebarSecondMenu = true;
    }
  }

  public openclass: boolean = false;
  public screenWidth: any;

  public collapseSidebarMainMenu = false;
  public collapseSidebarSecondMenu = false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth >= 991;

  public fullScreen = false;

  MENUITEMS: Menu[] = [
    {
      title: 'General', path: '/general/home', icon: 'pe-7s-home', type: 'sub', active: true, children:
        [
          {
            title: 'General', type: 'sub', active: true, children: [
              {path: '/general/home', title: 'Home', type: 'link'},
              {path: '/general/application-form', title: 'Application Form', type: 'link'}
            ]
          }
        ],
    },
    {
      title: 'LMS', path: '/lms/home', icon: 'pe-7s-monitor', type: 'sub', active: false, children:
        [
          {
            title: 'LMS', type: 'sub', active: true, children: [
              {path: '/lms/home', title: 'Home', type: 'link'},
              {path: '/lms/lectures', title: 'Lectures', type: 'link'},
              {path: '/lms/syllabus', title: 'Syllabus', type: 'link'},
              {path: '/lms/self-learning-materials', title: 'Self Learning Materials', type: 'link'},
              {path: '/lms/assignments', title: 'Assignments', type: 'link'},
            ]
          }
        ],
    },
    {
      title: 'Docs', path: '/docs/home', icon: 'pe-7s-photo-gallery', type: 'sub', active: false, children:
        [
          {
            title: 'Docs', type: 'sub', active: true, children: [
              {path: '/docs/home',title: 'Home',  type: 'link'},
              {path: '/docs/id-card', title: 'Id Card', type: 'link'},
              {path: '/docs/marksheet', title: 'Marksheet', type: 'link'},
              {path: '/docs/migration', title: 'Migration', type: 'link'},
              {path: '/docs/original-degree', title: 'Original Degree', type: 'link'},
              {path: '/docs/transcript', title: 'Transcript', type: 'link'}
            ]
          }
        ],
    },
    {
      title: 'Exam', path: '/exam/home', icon: 'pe-7s-note2', type: 'sub', active: false, children:
        [
          {
            title: 'Exam', type: 'sub', active: true, children: [
              {path: '/exam/home', title: 'Home', type: 'link'},
              {path: '/exam/exam', title: 'Exam', type: 'link'},
              {path: '/exam/form', title: 'Exam Form', type: 'link'},
              {path: '/exam/time-table', title: 'Exam Time Table', type: 'link'}
            ]
          },
        ],
    },
    {
      title: 'Results', path: '/results/home', icon: 'pe-7s-study', type: 'sub', active: false, children:
        [
          {
            title: 'Results', type: 'sub', active: true, children: [
              {path: '/results/home', title: 'Home', type: 'link'},
              {path: '/results/revaluation', title: 'Revaluation', type: 'link'},
              {path: '/results/result', title: 'Result', type: 'link'}
            ]
          }
        ],
    },
    {
      title: 'Notify', path: '/notify/home', icon: 'fas fa-bell', type: 'sub', active: false, children:
        [
          {
            title: 'Notify', type: 'sub', active: true, children: [
              {path: '/notify/home', title: 'Home', type: 'link'},
          /*    {path: '/notify/add', title: 'Notification', type: 'link'},*/
            ]
          }
        ],
    },
    /*{
      title: 'Locker', path: '/locker/home', icon: 'pe-7s-lock', type: 'sub', active: false, children:
        [
          {
            title: 'Digital Locker', type: 'sub', active: true, children: [
              {path: '/locker/home', title: 'Home', type: 'link'}
            ]
          }
        ],
    },*/
    {
      title: 'Support', path: '/support/home', icon: 'pe-7s-headphones', type: 'sub', active: false, children:
        [
          {
            title: 'Support', type: 'sub', active: true, children: [
              {path: '/support/home', title: 'Home', type: 'link'},
              {path: '/support/admission-tickets', title: 'Admission Tickets', type: 'link'},
              {path: '/support/re-registration-tickets', title: 'Re-Registration Tickets', type: 'link'},
              {path: '/support/examination-tickets', title: 'Examination Tickets', type: 'link'},
              {path: '/support/marksheet-tickets', title: 'Marksheet Tickets', type: 'link'},
              {path: '/support/transcript-tickets', title: 'Transcript Tickets', type: 'link'},
              {path: '/support/revaluation-tickets', title: 'Revalution Tickets', type: 'link'},
              {path: '/support/migration-tickets', title: 'Migration Tickets', type: 'link'},
              {path: '/support/original-degree-tickets', title: 'Original Degree Tickets', type: 'link'},
              {path: '/support/e-learning-tickets', title: 'E-Learning Tickets', type: 'link'},
              {path: '/support/other-tickets', title: 'Other Tickets', type: 'link'},
            ]
          }
        ],
    },
  ];
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }
}
