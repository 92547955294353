<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <ng-template #content let-modal>
        <div class="modal-header">
          <h4 class="modal-title">Update Profile</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-12">
              <form class="theme-form">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="col-form-label pt-0" for="current-password">Current Password</label>
                      <input class="form-control" id="current-password" type="status" aria-describedby="nameHelp"
                             placeholder="Enter Current Password">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="col-form-label pt-0" for="new-password">New Password</label>
                      <i class="fa fa-eye m-l-5 " *ngIf="password_icon"></i>
                      <i class="fa fa-eye-slash m-l-5" (click)="password_icon !== password_icon"></i>
                      <input class="form-control" id="new-password" type="status" aria-describedby="nameHelp"
                             placeholder="Enter New Password">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="col-form-label pt-0" for="confirm-password">Confirm Password</label>
                      <input class="form-control" id="confirm-password" type="status" aria-describedby="nameHelp"
                             placeholder="Enter Confirm Password">
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="modal-footer d-lg-block">
          <button class="btn btn-success mr-1 btn-pill pull-right"><i class="fa fa-user"></i> Update</button>
          <button class="btn btn-danger mr-1 btn-pill pull-left" (click)="modal.dismiss('Cross click')"><i
            class="fa fa-trash-o"></i> Cancel
          </button>
        </div>
      </ng-template>
      <div class="card">
        <div class="card-header">
          <!--Use class<code>.table-primary</code> inside thead tr element.-->
          <div class="row">
            <div class="col-12">
              <h5>Profile Form</h5>
            </div>
          </div>
        </div>
        <div class="card-body row">
          <div class="col-sm-12 col-lg-12 col-xl-12">
            <div class="row">
              <div class="col-sm-12">
                <form class="theme-form" [formGroup]="addProfileForm">
                  <div class="row">
                    <div class="col-12 avatar-showcase m-t-10 m-b-20">
                      <div class="avatars text-center">
                        <div class="avatar"><img class="img-100 rounded-circle" src="assets/images/user/10.jpg" alt="#">
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label pt-0" for="name">Name</label>
                        <input class="form-control" id="name" type="name" aria-describedby="nameHelp"
                               placeholder="Enter Name" formControlName="name">
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label pt-0" for="username">Username</label>
                        <input class="form-control" id="username" type="name" aria-describedby="nameHelp"
                               placeholder="Enter username" formControlName="username">
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label pt-0" for="email">Email</label>
                        <input class="form-control" id="email" type="name" aria-describedby="nameHelp"
                               placeholder="Enter Email" formControlName="email">
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label pt-0" for="contact">Contact</label>
                        <input class="form-control" id="contact" type="name" aria-describedby="nameHelp"
                               placeholder="Enter Contact" formControlName="contact">
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer d-lg-block padding-25">
          <button class="btn btn-primary mr-1 pull-right" (click)="update()"><i class="fa fa-user m-r-5"></i> Update
          </button>
          <button class="btn btn-secondary mr-1  pull-left" (click)="openLg(content)"><i class="fa fa-key m-r-5"></i>
            Update Password
          </button>

        </div>
      </div>
    </div>
  </div>
</div>
